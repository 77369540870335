
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BaseItem from "@/components/image/BaseImage.vue";
import { Post } from "@/types/components/posts";
import axios, { APIResponse } from "@/plugins/axios";
import AlertModule, { AlertType } from "@/store/modules/alertmodule";
import PostList from "@/components/posts/PostList.vue";
import Marquee from "@/components/Marquee.vue";

@Component({
    components: {
        BaseItem,
        PostList,
        Marquee,
    },
})
export default class Activities extends Vue {
    /*private posts: Post[] = [];

    private async created(): Promise<void> {
        await this.fetchData();
    }

    private async fetchData() {
        try {
            this.posts = await this.fetchActivities();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);
            console.log(errorMessage);
        }
    }

    private async fetchActivities(): Promise<Post[]> {
        const response = (
            await axios.get<APIResponse<Post[]>>("/posts/category/2")
        ).data;

        if (response.data.length <= 0) {
            console.log("Activities are empty");
        }
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }*/
}
